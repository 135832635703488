@import "../../../src/scss/variables";

.Sidebar {
  left: 0;
  top: 90px;
  z-index: 4;
  width: auto;
  height: 100%;
  display: flex;
  position: fixed;
  background: white;
  transition: $transition1;
  &--hidden {
    left: -100%;
  }
  &--sub-active {
    .SidebarSubMenu {
      opacity: 1;
      z-index: 1;
      left: 120px;
      visibility: visible;
    }
  }
  &__menu_container {
    width: 120px;
  }
  a {
    color: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .Sidebar {
    z-index: 11;
    height: 100vh;
    &__menu_container {
      width: 100px;
    }
    &--sub-active {
      .SidebarSubMenu {
        left: 100px;
        width: 170px;
        &__title {
          font-size: 1.1rem;
        }
      }
    }
  }
}
