@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-BookObl.woff2') format('woff2'),
        url('FuturaEF-BookObl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-Heavy.woff2') format('woff2'),
        url('FuturaEF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-ExtraBold.woff2') format('woff2'),
        url('FuturaEF-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-MediumCondObl.woff2') format('woff2'),
        url('FuturaEF-MediumCondObl.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-BoldCond.woff2') format('woff2'),
        url('FuturaEF-BoldCond.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-Light.woff2') format('woff2'),
        url('FuturaEF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-ExtraBoldShadowed.woff2') format('woff2'),
        url('FuturaEF-ExtraBoldShadowed.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-BoldCondObl.woff2') format('woff2'),
        url('FuturaEF-BoldCondObl.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-Book.woff2') format('woff2'),
        url('FuturaEF-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-Bold.woff2') format('woff2'),
        url('FuturaEF-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-DemiBold.woff2') format('woff2'),
        url('FuturaEF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-ExtraBoldCond.woff2') format('woff2'),
        url('FuturaEF-ExtraBoldCond.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-ExtraBoldObl.woff2') format('woff2'),
        url('FuturaEF-ExtraBoldObl.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-BoldObl.woff2') format('woff2'),
        url('FuturaEF-BoldObl.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-MediumCond.woff2') format('woff2'),
        url('FuturaEF-MediumCond.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-DemiBoldObl.woff2') format('woff2'),
        url('FuturaEF-DemiBoldObl.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-MediumObl.woff2') format('woff2'),
        url('FuturaEF-MediumObl.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-LightObl.woff2') format('woff2'),
        url('FuturaEF-LightObl.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-LightCondObl.woff2') format('woff2'),
        url('FuturaEF-LightCondObl.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-Medium.woff2') format('woff2'),
        url('FuturaEF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-ExtraBoldCondObl.woff2') format('woff2'),
        url('FuturaEF-ExtraBoldCondObl.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'FuturaEF';
    src: url('FuturaEF-LightCond.woff2') format('woff2'),
        url('FuturaEF-LightCond.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-LightItalic.woff2') format('woff2'),
        url('OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraBold.woff2') format('woff2'),
        url('OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.woff2') format('woff2'),
        url('OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Italic.woff2') format('woff2'),
        url('OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-BoldItalic.woff2') format('woff2'),
        url('OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiBold.woff2') format('woff2'),
        url('OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}