@import "../../scss/variables";

.Header {
  top: 0;
  left: 0;
  z-index: 6;
  position: fixed;
  max-height: 90px;
  background: white;
  .main_navigation {
    &__item {
      transition: $transition1;
      &:hover,
      &:focus,
      &--active {
        color: $mainColor !important;
      }
    }
  }
  &__logo {
    img {
      max-width: 160px;
    }
  }
  svg {
    &.fa-bars {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Header {
    padding-bottom: 1rem;
    border-bottom: 1px solid;
    &__logo {
      img {
        max-width: 130px;
      }
      &.p-4 {
        padding: 1rem !important;
      }
      .main_navigation {
        &__item {
          margin-bottom: 1rem;
          margin-right: 0 !important;
        }
      }
    }
  }
}
