@import "../../../../scss/variables";

.SidebarMainMenuItem {
  cursor: pointer;
  position: relative;
  .active {
    .SidebarMainMenuItem__title,
    svg,
    & > a {
      color: $mainColor;
    }
    &:before {
      opacity: 1;
    }
  }
  a {
    text-decoration: none !important;
    &:before {
      top: 0;
      left: 0;
      width: 4px;
      opacity: 0;
      content: "";
      height: 100%;
      position: absolute;
      background: #01ec96;
      transition: $transition1;
    }
  }
  &--disabled {
    &:after {
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      content: "";
      height: 100%;
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
    }
    cursor: unset !important;
  }
  svg,
  & > a {
    transition: all 0.3s ease-in-out;
  }
  &__title {
    text-align: center;
    font-size: 1rem !important;
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 767px) {
  .SidebarMainMenuItem {
    &__title {
      font-size: 0.9rem !important;
    }
  }
}
