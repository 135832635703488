// Transitions
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);

// Colors
$tertiary: #69bc58;
$mainColor: #01ec96;
$secondary: #e24a1a;
$mainBgColor: #dee2e6;

// General
$headerHeight: 90px;
$sidebarWidth: 139px;
