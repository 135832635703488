.MicroservicesItem {
  border-radius: 5px;
  background: white;
}

.MicroservicesLabel {
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 14px;
  }
  &__title {
    width: 40%;
    color: black;
  }
  &__value {
    width: 60%;
  }
}
