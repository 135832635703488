@import "./variables";

$theme-colors: (
  "primary": $mainColor,
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "../assets/fonts/stylesheet.css";

body {
  font-family: "FuturaEF", "Open Sans";
  font-size: 14px;
  font-weight: 400;
  color: #3a3a3a;
  background: #f8f8f8;
}

input,
textarea,
select,
option {
  font-family: "FuturaEF", "Open Sans";
  opacity: 1;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App--loading {
  min-height: 100vh;
}

.main_content {
  flex: 1;
  display: flex;
  position: relative;
  padding-top: $headerHeight;
}

.MainApp {
  width: 100%;
  display: flex;
  &__content {
    padding-left: $sidebarWidth;
  }
}

.secondary-font-family {
  font-family: "Open Sans";
}

.small-font-size {
  font-size: 0.9rem;
}

@media only screen and (max-width: 767px) {
  .main_content {
    padding-top: 60px;
  }
}

p {
  font-size: 18px;
}

.App {
  padding-top: 89px;
  padding-left: 119px;
}

.shadow_overlay {
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 5;
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.35);
}
